import loadable from "@loadable/component"
import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BreadcrumbModule = loadable(() =>
  import("../components/BreadcrumbModule/BreadcrumbModule")
)
const ListingIntroModule = loadable(() =>
  import("../components/ListingIntroModule/ListingIntroModule")
)
const TeamsTab = loadable(() => import("../components/TeamsTab/TeamsTab"))
const InnerPagination = loadable(() =>
  import("../components/InnerPagination/InnerPagination")
)

const Teams = ({ data }, props) => {
  const PageData = data?.strapiPage
  const team = data?.allStrapiTeam.edges
  return (
    <Layout dark popularSearch={PageData?.select_popular_search?.title}>
      <div className="layout-padding-top">
        <div className="">
          <BreadcrumbModule
            subparentlabel={PageData.choose_menu[0]?.strapi_parent?.title}
            subparentlink={PageData.choose_menu[0]?.strapi_parent?.slug}
            title={PageData.title}
            parentlabel={
              PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.title
            }
            parentlink={
              PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.slug
            }
          />
          <div className="white-bg">
            {PageData?.add_page_modules?.length > 0 &&
              PageData.add_page_modules?.map((module, index) => {
                return (
                  <div>
                    {module.strapi_component === "page-modules.plain-content" &&
                      index === 0 && (
                        <ListingIntroModule
                          content={module.content}
                          headingTitle={
                            module.title ? module.title : PageData.title
                          }
                        />
                      )}
                    {module.strapi_component === "page-modules.global-module" &&
                      module.select_module === "team" && (
                        <>
                          <TeamsTab data={team} />
                          <InnerPagination />
                        </>
                      )}
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage
  return (
    <SEO
      title={
        PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title
      }
      description={
        PageData?.seo?.metaDescription
          ? PageData?.seo?.metaDescription
          : PageData?.title
      }
    />
  )
}

export default Teams

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
          ...GlobalModuleFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
          ...PlainContentFragment
        }
      }
    }
    allStrapiTeam(
      sort: { fields: rank, order: ASC }
      filter: { publish: { eq: true } }
    ) {
      edges {
        node {
          ...TeamFragment
          slug
          category {
            strapi_json_value
          }
        }
      }
    }
  }
`
